<template>
  <v-dialog max-width="500" persistent v-model="dialog">
    <v-card>
      <v-card-title class="headline">
        <div>
          Rollen für {{ user.first_name }} {{ user.last_name }}<br />
          <div class="subheading grey--text">{{ user.username }}</div>
        </div>
      </v-card-title>
      <v-card-text>
        <div :key="role.name" class="mb-5" v-for="role in roles">
          <v-checkbox
            v-model="selected"
            :label="role.name"
            :value="role.name"
            class="mb-2"
            hide-details
          />
          <ul>
            <li :key="perm" v-for="perm in permissions[role.name]">
              {{ perm }}
            </li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn :loading="loading" @click="close">Abbrechen</v-btn>
        <v-btn :loading="loading" color="success" @click="updateRoles"
          >Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import serviceApi from '@/api/serviceApi'

const permissions = {
  Reviewer: ['Daten lesen (Stammdaten, Zahlungsdaten, Posteingang)'],
  Editor: ['Zahlungsdaten bearbeiten'],
  Accountant: ['BI Mappen schließen', 'Reporting', 'Stammdaten ändern'],
  Administrator: [
    'Customizing Daten ändern',
    'Mandanten verwalten',
    'Benutzerrollen verwalten',
  ],
}

export default {
  name: 'RolesModal',
  props: {
    value: Boolean,
    user: Object,
  },
  data() {
    return {
      roles: [],
      selected: [],
      loading: true,
      permissions,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      },
    },
  },
  methods: {
    close() {
      this.dialog = false
    },
    async getRoles() {
      this.loading = true
      try {
        this.roles = await serviceApi.getRoles()
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      } finally {
        this.loading = false
      }
    },
    async updateRoles() {
      this.loading = true
      try {
        await serviceApi.updateRoles(this.user.id, this.selected)
        this.$emit('updateUsers')
        this.close()
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    this.getRoles()
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.selected = this.user.roles.map((role) => role.name)
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
