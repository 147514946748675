<template>
  <v-card class="upload-card fill-height" flat>
    <v-data-table
      :headers="headers"
      :items="users"
      :pagination.sync="pagination"
      :loading="loading"
    >
      <template v-slot:items="props">
        <tr @click="openDialog(props.item)" style="cursor: pointer">
          <td>
            {{ props.item.first_name }}
            {{ props.item.last_name }} ({{ props.item.username }})
          </td>
          <td>
            <v-chip v-for="role in props.item.roles" :key="role.name"
              >{{ role.name }}
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <RolesModal
      :user="selectedUser"
      v-model="dialog"
      v-if="selectedUser"
      @updateUsers="getUsers"
    />
  </v-card>
</template>

<script>
import authApi from '@/api/authApi'
import RolesModal from '@/pages/admin/RolesModal'
import { mapGetters } from 'vuex'

export default {
  name: 'UserRoles',
  components: {
    RolesModal,
  },
  data() {
    return {
      selectedUser: null,
      dialog: false,
      loading: false,
      pagination: {
        sortBy: 'created_at',
        descending: true,
      },
      headers: [
        { text: 'Benutzer', value: 'username' },
        { text: 'Rollen', value: 'roles' },
      ],
      users: [],
    }
  },
  computed: {
    ...mapGetters(['permissions']),
  },
  methods: {
    async getUsers() {
      try {
        this.loading = true
        this.users = await authApi.getAll()
        await this.$store.dispatch('updateUser')
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
        throw e
      } finally {
        this.loading = false
      }
    },
    openDialog(user) {
      if (!this.loading) {
        this.selectedUser = user
        this.dialog = true
      }
    },
  },
  created() {
    this.getUsers()
  },
}
</script>

<style scoped></style>
